import React from 'react'

import WorkWithUsForm from './workWithUsForm'
import ContactDetails from './contactDetails'
import './contact.scss'

const WorkWithUs = () => (
  <div id='workWithUsSection' className='contact-container page-section'>
    <h2>Work with us</h2>
    <p>
      Thank you for your interest in working with us. Please send in your application by filling out this form.
    </p>
    <WorkWithUsForm />
    <ContactDetails />
  </div>
)

export default WorkWithUs
