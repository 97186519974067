import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { commonTags } from '../config/constants'
import WorkWithUsSection from '../components/contact/workWithUs'
import Layout from '../layouts'

const WorkWithUsPage = ({ data, location }) => {
  const { contentfulPage } = data
  const {
    ogTitle, ogKeywords, ogDescription, ogImage, favIco,
  } = contentfulPage

  return (
    <Layout>
      <Helmet
        title={ogTitle}
        meta={commonTags(ogTitle, ogDescription, ogKeywords, ogImage)}
      >
        <link rel='icon' type='image/png' href={favIco.file.url}  />
      </Helmet>
      <div className='mobile-menu-placeholder' />
      <WorkWithUsSection location={location} />
    </Layout>)
}

export const pageDataQuery = graphql`
  query workWithUsPageDataTemplateQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      ogTitle
      ogDescription
      ogKeywords
      ogImage {
        description
        file {
          url
          contentType
          details {
            size
            image {
              width
              height
            }
          }
        }
      }
      heroText
      favIco {
        file {
          url
        }
      }
    }
  }
`

WorkWithUsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default WorkWithUsPage
