import React from 'react'
import ReactGA from 'react-ga'

import Button from '../button'

import './form.scss'
import { WORK_WITH_US_FORM } from '../../config/analytics-events'
import { encodeForm } from './utils'

const jobOptions = [
  'Experienced Cleaner Full time',
  'Local Cleaning Assistant-Mission Bay',
  'Local Cleaning Assistant-Birkenhead',
  'Cleaner-North Shore Part time',
  'Cleaner-Albany Fixed Term Full-time',
  'Cleaner-Albany Part time',
  'Commercial (early morning) Cleaner Fixed term',
  'Commercial (evening) Cleaner Fixed term',
  'Cleaning Supervisor',
  'Marketing (Door to Door)',
  'Marketer (Telemarketing)',
  'Customer Service',
]

const workConditions = [
  'NZ Citizen',
  'NZ Resident',
  'Full time work visa',
  'Student visa',
  'Other',
]

const yesNoOptions = ['Yes', 'No']

const emptyFields = {
  inputName: '',
  inputEmail: '',
  inputPhone: '',
  inputAddress: '',
  inputDateOfBirth: '',
  inputNationality: '',
  inputDriverLicenseYesNo: '',
  inputTimeAvailableToWork: '',
  inputExperienceCleaning: '',
  inputSiteSafePassport: '',
  inputNameOnBankAccount: '',
  inputBankAccountNumber: '',
  inputIRDNumber: '',
  inputTaxCode: '',
  inputPersonalProtectiveEquipment: '',
  inputApplyingForJob: '',
  inputAvailableStartDate: '',
  inputWorkCondition: '',
}

class WorkWithUsForm extends React.Component {
  constructor() {
    super()
    this.state = {
      ...emptyFields,
      contactMessage: null,
      sending: false,
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  resetForm = () => {
    this.setState(emptyFields)
  }

  submitForm = (e) => {
    const _this = this
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
      return false
    }
    const {
      inputName,
      inputEmail,
      inputPhone,
      inputApplyingForJob,
      inputAvailableStartDate,
      inputWorkCondition,
      inputAddress,
      inputDateOfBirth,
      inputNationality,
      inputDriverLicenseYesNo,
      inputTimeAvailableToWork,
      inputExperienceCleaning,
      inputSiteSafePassport,
      inputNameOnBankAccount,
      inputBankAccountNumber,
      inputIRDNumber,
      inputTaxCode,
      inputPersonalProtectiveEquipment,
    } = this.state
    e.preventDefault()
    this.setState({ sending: true })
    fetch('/php/work-with-us.php', {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: encodeForm({
        emailTitle: 'Job Application Request - HygieNZ website',
        inputName,
        inputPhone,
        inputEmail,
        inputApplyingForJob,
        inputAvailableStartDate,
        inputWorkCondition,
        inputAddress,
        inputDateOfBirth,
        inputNationality,
        inputDriverLicenseYesNo,
        inputTimeAvailableToWork,
        inputExperienceCleaning,
        inputSiteSafePassport,
        inputNameOnBankAccount,
        inputBankAccountNumber,
        inputIRDNumber,
        inputTaxCode,
        inputPersonalProtectiveEquipment,
      }),
    })
      .then((res) => {
        this.setState({ sending: false })
        if (res.ok) {
          ReactGA.event({
            category: WORK_WITH_US_FORM.category,
            action: WORK_WITH_US_FORM.successSubmitAction,
          })
          return res.json()
        }
        ReactGA.event({
          category: WORK_WITH_US_FORM.category,
          action: WORK_WITH_US_FORM.errorSubmitAction,
        })
        return { error: 'Error sending form, please try again later.' }
      })
      .then((data) => {
        if (data.error || !data.success) {
          _this.setState({ contactMessage: { success: false, message: data.messageEnglish } })
        } else {
          _this.resetForm()
          _this.setState({ contactMessage: { success: true, message: data.messageEnglish } })
        }
      })
      .catch((error) => {
        console.error(error)
        ReactGA.event({
          category: WORK_WITH_US_FORM.category,
          action: WORK_WITH_US_FORM.errorSubmitAction,
        })
        this.setState({ sending: false })
      })
  }

  getJobOptions = () => (
    jobOptions.map((job) => (
      <option value={job}>
        {job}
      </option>
    ))
  )

  getYesNoOptions = () => (
    yesNoOptions.map((option) => (
      <option value={option} key={option}>
        {option}
      </option>
    ))
  )

  getWorkConditionsOptions = () => (
    workConditions.map((workCondition) => (
      <option value={workCondition}>
        {workCondition}
      </option>
    ))
  )


  render() {
    const {
      contactMessage,
      inputName,
      inputEmail,
      inputPhone,
      inputApplyingForJob,
      inputAvailableStartDate,
      inputWorkCondition,
      sending,
      inputAddress,
      inputDateOfBirth,
      inputNationality,
      inputDriverLicenseYesNo,
      inputTimeAvailableToWork,
      inputExperienceCleaning,
      inputSiteSafePassport,
      inputNameOnBankAccount,
      inputBankAccountNumber,
      inputIRDNumber,
      inputTaxCode,
      inputPersonalProtectiveEquipment,
    } = this.state

    return (
      <div className='contact-form work-with-us-form'>
        <form id='work_with_us_form' name='workWithUsForm' data-toggle='validator' onSubmit={this.submitForm}>
          <div className='form-group'>
            <input
              value={inputName}
              onChange={this.handleChange}
              name='inputName'
              min='2'
              id='inputName'
              placeholder='Name'
              type='text'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputEmail}
              onChange={this.handleChange}
              name='inputEmail'
              id='inputEmail'
              placeholder='Email'
              type='email'
              required
            />
            <input
              value={inputPhone}
              onChange={this.handleChange}
              name='inputPhone'
              id='inputPhone'
              placeholder='Phone'
              type='text'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputAddress}
              onChange={this.handleChange}
              name='inputAddress'
              id='inputAddress'
              placeholder='Address'
              type='text'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputDateOfBirth}
              onChange={this.handleChange}
              name='inputDateOfBirth'
              id='inputDateOfBirth'
              placeholder='Date of birth DD/MM/YYYY'
              type='text'
            />
            <input
              value={inputNationality}
              onChange={this.handleChange}
              name='inputNationality'
              id='inputNationality'
              placeholder='Nationality'
              type='text'
            />
          </div>
          <div className='form-group'>
            <select
              value={inputDriverLicenseYesNo}
              onChange={this.handleChange}
              name='inputDriverLicenseYesNo'
              id='inputDriverLicenseYesNo'
              required
            >
              <option value='' disabled selected>Do you have a Drivers License?</option>
              {this.getYesNoOptions()}
            </select>
            <select
              value={inputApplyingForJob}
              onChange={this.handleChange}
              name='inputApplyingForJob'
              id='inputApplyingForJob'
              required
            >
              <option value='' disabled selected>Position you are applying for</option>
              {this.getJobOptions()}
            </select>
          </div>
          <div className='form-group'>
            <input
              value={inputTimeAvailableToWork}
              onChange={this.handleChange}
              name='inputTimeAvailableToWork'
              id='inputTimeAvailableToWork'
              placeholder='What time are you available to work? (Daytime, evenings, weekends, etc)'
              type='text'
            />
          </div>
          <div className='form-group'>
            <input
              value={inputAvailableStartDate}
              onChange={this.handleChange}
              name='inputAvailableStartDate'
              id='inputAvailableStartDate'
              placeholder='Available start date DD/MM/YYYY'
              type='text'
            />
          </div>
          <div className='form-group'>
            <input
              value={inputExperienceCleaning}
              onChange={this.handleChange}
              name='inputExperienceCleaning'
              id='inputExperienceCleaning'
              placeholder='Do you have experience with cleaning? If yes, what type of cleaning?'
              type='text'
            />
          </div>
          <div className='form-group'>
            <input
              value={inputPersonalProtectiveEquipment}
              onChange={this.handleChange}
              name='inputPersonalProtectiveEquipment'
              id='inputPersonalProtectiveEquipment'
              placeholder='What equipment do you own? Steel cap boots, Hard hat, Gloves, etc.'
              type='text'
            />
          </div>
          <div className='form-group'>
            <select
              value={inputSiteSafePassport}
              onChange={this.handleChange}
              name='inputSiteSafePassport'
              id='inputSiteSafePassport'
            >
              <option value='' disabled selected>Do you have a Site Safe Passport?</option>
              {this.getYesNoOptions()}
            </select>
            <select
              value={inputWorkCondition}
              onChange={this.handleChange}
              name='inputWorkCondition'
              id='inputWorkCondition'
              required
            >
              <option value='' disabled selected>Working condition</option>
              {this.getWorkConditionsOptions()}
            </select>
          </div>

          <div className='form-group'>
            <input
              value={inputIRDNumber}
              onChange={this.handleChange}
              name='inputIRDNumber'
              id='inputIRDNumber'
              placeholder='IRD number'
              type='text'
              required
            />
            <input
              value={inputTaxCode}
              onChange={this.handleChange}
              name='inputTaxCode'
              id='inputTaxCode'
              placeholder='Tax Code'
              type='text'
            />
          </div>

          <div className='form-group'>
            <input
              value={inputNameOnBankAccount}
              onChange={this.handleChange}
              name='inputNameOnBankAccount'
              id='inputNameOnBankAccount'
              placeholder='Name on bank account'
              type='text'
            />
            <input
              value={inputBankAccountNumber}
              onChange={this.handleChange}
              name='inputBankAccountNumber'
              id='inputBankAccountNumber'
              placeholder='Bank account number'
              type='text'
            />
          </div>
          <div className='form-group'>
            <Button isButton type='submit' disabled={sending}>
              {sending ? 'Sending...' : 'Send'}
            </Button>
          </div>
          <div className='contact-message-container'>
            {contactMessage && contactMessage.success
              && <p className='contact-message-success'>{contactMessage.message}</p>
            }
            {contactMessage && !contactMessage.success
              && <p className='contact-message-error'>{contactMessage.message}</p>
            }
          </div>
        </form>
      </div>)
  }
}

export default WorkWithUsForm
